const counts = [
  {
    icon: "icofont-simple-smile",
    value: 17,
    heading: "Clients satisfaits",
    text: "depuis notre lancement en Guadeloupe",
  },
  {
    icon: "icofont-document-folder",
    value: 24,
    heading: "Projets",
    text: "terminés ou en cours de développement",
  },
  {
    icon: "icofont-clock-time",
    value: 13,
    heading: "Années d'experience",
    text: "combinés",
  },
  {
    icon: "icofont-award",
    value: 5,
    heading: "Récompenses",
    text: "internationales",
  },
]

export default counts
