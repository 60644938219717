import React from "react"
import PropTypes from "prop-types"

import counts from "../data/counts"
// Static assets
import countsImage from "../assets/img/counts-img.svg"

const CountItem = ({ icon, value, heading, text }) => (
  <div className="col-md-6 d-md-flex align-items-md-stretch">
    <div className="count-box">
      <i className={icon}></i>
      <span data-toggle="counter-up">{value}</span>
      <p>
        <strong>{heading}</strong> {text}
      </p>
    </div>
  </div>
)

CountItem.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const Counts = () => (
  <section id="counts" className="counts">
    <div className="container">
      <div className="row">
        <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start">
          <img src={countsImage} alt="" className="img-fluid" />
        </div>

        <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0">
          <div className="content d-flex flex-column justify-content-center">
            <div className="row">
              {counts.map((count, i) => (
                <CountItem {...count} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Counts
