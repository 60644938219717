import React from "react"
import { Link } from "gatsby"

const About = () => (
  <section id="about" className="about">
    <div className="container">
      <div className="section-title">
        <h2>À propos</h2>
      </div>

      <div className="row content">
        <div className="col-lg-6">
          <p>
            Des Guadeloupéens passionnés par l'entrepreunariat qui mettons nos
            compétences techniques au services des entreprises locales. Nous
            vous aidons à :
          </p>
          <ul>
            <li>
              <i className="ri-check-double-line"></i> Attirer plus de clients,
            </li>
            <li>
              <i className="ri-check-double-line"></i> Augmenter votre
              productvité,
            </li>
            <li>
              <i className="ri-check-double-line"></i> Vous débarasser des
              tâches répétitives.
            </li>
          </ul>
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0">
          <p>
            Après avoir exercé nos talents en France et en Europe, nous pensons
            qu'il est temps de permettre aux entrepreneurs locaux de se
            redynamiser.
          </p>
          <Link to="#contact" className="btn-learn-more">
            Contactez nous
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default About
