import React from "react"
import PropTypes from "prop-types"

import { portfolios } from "../data/portfolios"
import { Link } from "gatsby"

const PortfolioItem = ({ category, image, title, path, url }) => (
  <div className={`col-lg-4 col-md-6 portfolio-item filter-${category}`}>
    <div className="portfolio-wrap">
      <img src={image} className="img-fluid" alt="" />
      <div className="portfolio-info">
        <h4>{title}</h4>
        <p>{category}</p>
        <div className="portfolio-links">
          <Link to={path} title={title}>
            <i className="bx bx-plus"></i>
          </Link>
          <a href={url} title="Demo" target="_blank">
            <i className="bx bx-link"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
)

PortfolioItem.propTypes = {
  image: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const Portfolio = ({ projects }) => (
  <section id="portfolio" className="portfolio">
    <div className="container">
      <div className="section-title">
        <h2>Portfolio</h2>
        <p>Un aperçu de nos travaux</p>
      </div>

      {/* <div className="row">
        <div className="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" className="filter-active">
              All
            </li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Cartes</li>
            <li data-filter=".filter-web">Web</li>
          </ul>
        </div>
      </div> */}

      <div className="row portfolio-container">
        {portfolios.map((props, i) => (
          <PortfolioItem {...props} key={i} />
          // projects.map((props, i) => (
          //   <PortfolioItem {...props.node.frontmatter} key={i} />
        ))}
      </div>
    </div>
  </section>
)

export default Portfolio
