import React from "react"
import PropTypes from "prop-types"

const Contact = ({ siteTitle, siteSubtitle, siteDescription }) => (
  <section id="contact" className="contact">
    <div className="container">
      <div className="section-title">
        <h2>Contactez nous</h2>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="contact-about">
            <h3>{siteTitle}</h3>
            <p>
              {siteSubtitle}.<br /> {siteDescription}
            </p>
            <div className="social-links">
              <a href="https://twitter.com/" className="twitter">
                <i className="icofont-twitter"></i>
              </a>
              <a href="http://facebook.com/" className="facebook">
                <i className="icofont-facebook"></i>
              </a>
              <a href="http://instagram.com/" className="instagram">
                <i className="icofont-instagram"></i>
              </a>
              <a href="http://linkedin.com/" className="linkedin">
                <i className="icofont-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
          <div className="info">
            {/* <div>
              <i className="ri-map-pin-line"></i>
              <p>
                Fond de Bragelogne
                <br />
                97122 Baie-Mahault
                Guadeloupe
              </p>
            </div> */}

            <div>
              <i className="ri-mail-send-line"></i>
              <p>
                <a href="mailto:hello@celestine.dev">hello@agence.dev</a>
              </p>
            </div>

            <div>
              <i className="ri-phone-line"></i>
              <p>
                <a href="tel:+33658961279">06 58 96 12 79</a>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-12">
          <form
            name="contact"
            method="post"
            className="email-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success"
          >
            <input
              type="hidden"
              name="form-name"
              value="contact"
              aria-label="hidden"
            />
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Nom"
                data-rule="minlen:4"
                data-msg="Entrez au moins 4 caractères"
                aria-label="name"
              />
              <div className="validate"></div>
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Adresse Email"
                data-rule="email"
                data-msg="Entrez une adresse email valide"
                aria-label="email"
              />
              <div className="validate"></div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder="Sujet"
                data-rule="minlen:4"
                data-msg="Entrez au moins 8 caractères"
                aria-label="subject"
              />
              <div className="validate"></div>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                data-rule="required"
                data-msg="Laissez nous un message"
                placeholder="Message"
                aria-label="message"
              ></textarea>
              <div className="validate"></div>
            </div>
            <div className="mb-3">
              <div className="loading">Chargement</div>
              <div className="error-message"></div>
              <div className="sent-message">
                Votre message a bien été envoyé. Merci.
              </div>
            </div>
            <div className="text-center">
              <button type="submit">Envoyer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
)

export default Contact

Contact.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  siteSubtitle: PropTypes.string.isRequired,
  siteDescription: PropTypes.string.isRequired,
}
