import img1 from "../assets/img/testimonials/testimonials-1.jpg"

export const testimonials = [
  {
    image: img1,
    name: "Sara Wilsson",
    position: "Designer",
    text:
      "Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.",
  },
]
