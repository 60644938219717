import React from "react"
import PropTypes from "prop-types"

import { testimonials } from "../data/testimonials"

const TestimonialItem = ({ image, name, position, text }) => (
  <div className="testimonial-wrap">
    <div className="testimonial-item">
      <img src={image} className="testimonial-img" alt="client satisfait" />
      <h3>{name}</h3>
      <h4>{position}</h4>
      <p>
        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
        {text}
        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
      </p>
    </div>
  </div>
)

TestimonialItem.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const Testimonials = () => (
  <section id="testimonials" className="testimonials section-bg">
    <div className="container">
      <div className="section-title">
        <h2>Témoignages</h2>
        <p>Ce que vous pensez de nous</p>
      </div>
      <div>
        {testimonials.map((props, i) => (
          <TestimonialItem {...props} key={i} />
        ))}
      </div>
    </div>
  </section>
)

export default Testimonials
