import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
// Static assets
import heroImage from "../assets/img/hero-img.png"

const Hero = ({ siteDescription, siteSubtitle }) => (
  <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Donnez vie à vos projets</h1>
          <h2>{siteDescription}</h2>
          <div>
            <Link to="#contact" className="btn-get-started scrollto">
              En Savoir Plus
            </Link>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src={heroImage} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
)

export default Hero

Hero.propTypes = {
  siteDescription: PropTypes.string.isRequired,
  siteSubtitle: PropTypes.string.isRequired,
}
