import img1 from "../assets/img/portfolio/portfolio-1.png"
import img2 from "../assets/img/portfolio/portfolio-2.png"
import img3 from "../assets/img/portfolio/portfolio-3.png"

export const portfolios = [
  {
    image: img1,
    category: "App",
    title: "Chef's Meal Planner",
    url: "https://chefs-meal-planner.onrender.com/",
    path: "/portfolio/chefs",
  },
  {
    image: img2,
    category: "App",
    title: "DevBook",
    url: "https://devprojects-4749c.web.app/",
    path: "/portfolio/devbook",
  },
  {
    image: img3,
    category: "Web",
    title: "VillaFleurie",
    url: "http://villafleuriegp.com/",
    path: "/portfolio/villafleurie",
  },
]
