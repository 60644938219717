import React from "react"
import PropTypes from "prop-types"

import { servicesBoxes } from "../data/services"
import { Link } from "gatsby"

const ServicesBox = ({ icon, title, description }) => (
  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
    <div className="icon-box">
      <div className="icon">
        <i className={icon}></i>
      </div>
      <h4 className="title">
        <Link to="/">{title}</Link>
      </h4>
      <p className="description">{description}</p>
    </div>
  </div>
)

ServicesBox.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const ServicesCard = ({ backgroundImage, title, text }) => (
  <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
    <div
      class="card"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
    >
      <div class="card-body">
        <h5 class="card-title">
          <Link to="/">{title}</Link>
        </h5>
        <p class="card-text">{text}.</p>
        <div class="read-more">
          <Link to="/">
            <i class="icofont-arrow-right"></i> En Savoir Plus
          </Link>
        </div>
      </div>
    </div>
  </div>
)

ServicesCard.propTypes = {
  backgroundImage: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

const Services = () => (
  <>
    <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <p>Tout ce qu'on peut faire pour vous</p>
        </div>

        <div className="row">
          {servicesBoxes.map((props, i) => (
            <ServicesBox {...props} key={i} />
          ))}
        </div>
      </div>
    </section>

    {/* <section id="more-services" class="more-services">
      <div class="container">
        <div class="row">
          {servicesCards.map((props, i) => (
            <ServicesCard {...props} key={i} />
          ))}
        </div>
      </div>
    </section> */}
  </>
)

export default Services
