import img1 from "../assets/img/more-services-1.jpg"
import img2 from "../assets/img/more-services-2.jpg"
import img3 from "../assets/img/more-services-4.jpg"
import img4 from "../assets/img/more-services-3.jpg"

export const servicesBoxes = [
  {
    icon: "bx bxl-dribbble",
    title: "Web design",
    description: "Un site à votre image pour capter plus de clients",
  },
  {
    icon: "bx bx-tachometer",
    title: "Productivité",
    description: "Des outils sur mesure pour faciliter votre travail quotidien",
  },
  {
    icon: "bx bx-world",
    title: "International",
    description: "Étendez votre clientèle",
  },
  {
    icon: "bx bx-file",
    title: "Cloud",
    description: "Vos données et celles de vos clients en sécurité",
  },
]

export const servicesCards = [
  {
    backgroundImage: img1,
    title: "Lobira Duno",
    text: "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod",
  },
  {
    backgroundImage: img2,
    title: "Limere Radses",
    text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
  },
  {
    backgroundImage: img3,
    title: "Nive Lodo",
    text: "Nemo enim ipsam voluptatem quia voluptas sit aut odit aut",
  },
  {
    backgroundImage: img4,
    title: "Pale Treda",
    text: "Nostrum eum sed et autem dolorum perspiciatis. Magni porro",
  },
]
