import ruidy from "../assets/img/team/team-1.jpg"
import image3 from "../assets/img/team/team-3.jpg"

export const team = [
  {
    image: ruidy,
    name: "Ruidy",
    position: "Directeur Technique",
  },
  {
    image: image3,
    name: "Samuel",
    position: "Directeur des Opérations",
  },
]
