import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Clients from "../components/clients"
import About from "../components/about"
import Counts from "../components/counts"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import Portfolio from "../components/portfolio"
import Team from "../components/team"
import Contact from "../components/contact"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={data.site.siteMetadata.subtitle}
      keywords={[`développement logiciel`, `agence`, `web`]}
    />
    <Hero
      siteDescription={data.site.siteMetadata.description}
      siteSubtitle={data.site.siteMetadata.subtitle}
    />
    <Clients />
    <About />
    <Counts />
    <Services />
    <Testimonials />
    <Portfolio projects={data.allMarkdownRemark.edges} />
    <Team />
    <Contact
      siteTitle={data.site.siteMetadata.title}
      siteSubtitle={data.site.siteMetadata.subtitle}
      siteDescription={data.site.siteMetadata.description}
    />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        description
        subtitle
        title
      }
    }
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "project" } } }) {
      edges {
        node {
          id
          frontmatter {
            image
            category
            title
            url
          }
        }
      }
    }
  }
`
