import React from "react"
import PropTypes from "prop-types"

import { team } from "../data/team"

const TeamItem = ({ image, name, position }) => (
  <div className="col-md-6 d-flex align-items-stretch">
    <div className="member">
      <div className="member-img">
        <img src={image} className="img-fluid" alt="" />
        <div className="social">
          <a href="https://twitter.com/">
            <i className="icofont-twitter"></i>
          </a>
          <a href="http://facebook.com/">
            <i className="icofont-facebook"></i>
          </a>
          <a href="http://instagram.com/">
            <i className="icofont-instagram"></i>
          </a>
          <a href="http://linkedin.com/">
            <i className="icofont-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="member-info">
        <h4>{name}</h4>
        <span>{position}</span>
      </div>
    </div>
  </div>
)

TeamItem.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
}

const Team = () => (
  <section id="team" className="team section-bg">
    <div className="container">
      <div className="section-title">
        <h2>L'Équipe</h2>
        <p>Des experts à votre service</p>
      </div>

      <div className="row">
        {team.map((props, i) => (
          <TeamItem {...props} key={i} />
        ))}
      </div>
    </div>
  </section>
)
export default Team
