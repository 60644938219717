import React from "react"
import PropTypes from "prop-types"
// Static assets
import clientImage1 from "../assets/img/clients/client-1.png"
import clientImage2 from "../assets/img/clients/client-2.png"
import clientImage3 from "../assets/img/clients/client-3.png"
import clientImage4 from "../assets/img/clients/client-4.png"
import clientImage5 from "../assets/img/clients/client-5.png"
import clientImage6 from "../assets/img/clients/client-6.png"

const clientImages = [
  clientImage1,
  clientImage2,
  clientImage3,
  clientImage4,
  clientImage5,
  clientImage6,
]

const ClientItem = ({ clientImage }) => (
  <div className="col-lg-2 col-md-4 col-6">
    <img src={clientImage} className="img-fluid" alt="client" />
  </div>
)

ClientItem.propTypes = {
  clientImage: PropTypes.node.isRequired,
}

const Clients = () => (
  <section id="clients" className="clients clients">
    <div className="container">
      <div className="row">
        {clientImages.map((img, i) => (
          <ClientItem clientImage={img} key={i} />
        ))}
      </div>
    </div>
  </section>
)

export default Clients
